import { Navigate } from "react-router-dom";
import { Projects } from "./AuthedView";

export const Home: React.FC<{ projects: Projects }> = ({ projects }) => {
  return projects?.length == 0 ? (
    <Navigate to="project/new" />
  ) : (
    <Navigate to={`project/${projects[projects.length - 1].projectId}`} />
  );
};
