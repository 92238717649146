import { SideNavigation } from "@cloudscape-design/components";
import { OnNavigate } from "./App";
import { Projects } from "./AuthedView";

export const ProjectList: React.FC<{
  activeHref: string;
  onNavigate: OnNavigate;
  projects: Projects;
}> = ({ activeHref, onNavigate, projects }) => {
  return (
    <SideNavigation
      header={{ text: "Projects", href: "/" }}
      activeHref={activeHref}
      onFollow={onNavigate}
      items={[
        {
          type: "link-group",
          text: "Projects",
          href: "#",
          items: projects.map((p) => ({
            text: p.projectName,
            type: "link",
            href: `/project/${p.projectId}`,
          })),
        },
        {
          type: "link",
          text: "New Project",
          href: "/project/new",
        },
      ]}
    />
  );
};
