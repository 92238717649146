/* tslint:disable */
/* eslint-disable */
/**
 * The Project Estimation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskEstimate
 */
export interface TaskEstimate {
    /**
     * 
     * @type {string}
     * @memberof TaskEstimate
     */
    taskId?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskEstimate
     */
    lowerBound?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskEstimate
     */
    upperBound?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskEstimate
     */
    confidence?: number;
}


/**
 * Check if a given object implements the TaskEstimate interface.
 */
export function instanceOfTaskEstimate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaskEstimateFromJSON(json: any): TaskEstimate {
    return TaskEstimateFromJSONTyped(json, false);
}

export function TaskEstimateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskEstimate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'lowerBound': !exists(json, 'lowerBound') ? undefined : json['lowerBound'],
        'upperBound': !exists(json, 'upperBound') ? undefined : json['upperBound'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
    };
}

export function TaskEstimateToJSON(value?: TaskEstimate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskId': value.taskId,
        'lowerBound': value.lowerBound,
        'upperBound': value.upperBound,
        'confidence': value.confidence,
    };
}

