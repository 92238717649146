/* tslint:disable */
/* eslint-disable */
/**
 * The Project Estimation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskItem } from './TaskItem';
import {
    TaskItemFromJSON,
    TaskItemFromJSONTyped,
    TaskItemToJSON,
} from './TaskItem';

/**
 * 
 * @export
 * @interface ListTasksResponseContent
 */
export interface ListTasksResponseContent {
    /**
     * 
     * @type {Array<TaskItem>}
     * @memberof ListTasksResponseContent
     */
    tasks?: Array<TaskItem>;
}


/**
 * Check if a given object implements the ListTasksResponseContent interface.
 */
export function instanceOfListTasksResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListTasksResponseContentFromJSON(json: any): ListTasksResponseContent {
    return ListTasksResponseContentFromJSONTyped(json, false);
}

export function ListTasksResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTasksResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tasks': !exists(json, 'tasks') ? undefined : ((json['tasks'] as Array<any>).map(TaskItemFromJSON)),
    };
}

export function ListTasksResponseContentToJSON(value?: ListTasksResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tasks': value.tasks === undefined ? undefined : ((value.tasks as Array<any>).map(TaskItemToJSON)),
    };
}

