import {
  Box,
  Container,
  Header,
  Icon,
  Input,
  Link,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
  TextContent,
  Button,
  ColumnLayout,
} from "@cloudscape-design/components";
import { TaskItem } from "@estimatron/backend-typescript-react-query-hooks";
import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Projects, SetActiveHref, SetProjects } from "./AuthedView";

import { CumSumChart } from "./CumSumChart";
import { DeleteProject } from "./DeleteProject";
import { useApi } from "./hooks/useApi";
import { checkBounds, checkConfidence, reducer, State } from "./projectReducer";
import { syncTasks } from "./sync/taskSync";

const initialState: State = {
  tasks: [],
  data: [],
  taskUpdates: [],
  projectName: "",
  isLoadingTasks: true,
  isConfidenceValid: {},
  areBoundsValid: {},
};

const ProjectView: React.FC<{
  projects: Projects;
  setProjects: SetProjects;
  setActiveHref: SetActiveHref;
}> = ({ projects, setProjects, setActiveHref }) => {
  const apiClient = useApi();
  const params = useParams();
  const projectId = params.projectId;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setActiveHref(window.location.pathname);
  }, [setActiveHref]);

  useEffect(() => {
    dispatch({
      type: "loadProject",
      payload: {
        projectName:
          projects.find((p) => projectId === p.projectId)?.projectName || "",
      },
    });
  }, [projectId, projects]);

  useEffect(() => {
    let active = true;
    void (async () => {
      if (projectId && apiClient) {
        const response = await apiClient.listTasks({
          projectId,
        });
        if (active && response.tasks) {
          dispatch({ type: "tasksLoaded", payload: response.tasks });
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [apiClient, projectId]);

  useEffect(() => {
    let active = true;
    void (async () => {
      const estimateProjectRequest = {
        estimateRequestContent: {
          tasks: state.tasks.filter(
            (t) =>
              checkBounds(t.lowerBound, t.upperBound) &&
              checkConfidence(t.confidence),
          ),
        },
      };
      const response = await apiClient?.estimate(estimateProjectRequest);
      if (response) {
        if (active) {
          dispatch({ type: "setSamples", payload: response.samples });
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [apiClient, state.tasks]);

  useEffect(() => {
    let active = true;
    void (async () => {
      if (apiClient && state.taskUpdates.length > 0) {
        const updatedTasks = await syncTasks(state.taskUpdates, apiClient);
        if (active) {
          dispatch({ type: "updatesApplied", payload: updatedTasks });
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [apiClient, state.taskUpdates]);

  const columnDefinitions: TableProps<TaskItem>["columnDefinitions"] = [
    {
      id: "taskName",
      sortingField: "taskName",
      header: "Task",
      cell: (item) => (
        <div>
          <Input
            type="text"
            value={item.taskName}
            onChange={(e) =>
              dispatch({
                type: "setTaskName",
                payload: {
                  taskId: item.taskId,
                  newTaskName: e.detail.value,
                  projectId: projectId as string,
                },
              })
            }
          />
        </div>
      ),
      minWidth: 90,
    },
    {
      id: "lowerBound",
      sortingField: "lowerBound",
      header: "Low",
      cell: (item) => (
        <div>
          <Input
            invalid={!state.areBoundsValid[item.taskId]}
            type="number"
            value={item.lowerBound?.toString() || "0"}
            onChange={(element) => {
              const value = parseFloat(element.detail.value);
              dispatch({
                type: "setLowerBound",
                payload: {
                  taskId: item.taskId,
                  value,
                  projectId: projectId || "",
                },
              });
            }}
          />
        </div>
      ),
      minWidth: 40,
    },
    {
      id: "upperBound",
      sortingField: "upperBound",
      header: "High",
      cell: (item) => (
        <div>
          <Input
            type="number"
            invalid={!state.areBoundsValid[item.taskId]}
            value={item.upperBound?.toString() || "0"}
            onChange={(element) => {
              const value = parseFloat(element.detail.value);
              dispatch({
                type: "setUpperBound",
                payload: {
                  taskId: item.taskId,
                  value,
                  projectId: projectId || "",
                },
              });
            }}
          />
        </div>
      ),
      minWidth: 40,
    },
    {
      id: "confidence",
      sortingField: "confidence",
      header: "Conf. %",
      cell: (item) => (
        <div>
          <Input
            type="number"
            invalid={!state.isConfidenceValid[item.taskId]}
            value={item.confidence?.toString() || "0"}
            onChange={(e) => {
              const value = parseFloat(e.detail.value);
              dispatch({
                type: "setConfidence",
                payload: {
                  taskId: item.taskId,
                  value,
                  projectId: projectId || "",
                },
              });
            }}
          />
        </div>
      ),
      minWidth: 40,
    },
    {
      id: "delete",
      header: "",
      cell: (item) => (
        <div className="noPrint">
          <Link
            onFollow={() =>
              dispatch({
                type: "removeTask",
                payload: { taskId: item.taskId, projectId: projectId || "" },
              })
            }
            href="#"
          >
            <Icon name="close" />
          </Link>
        </div>
      ),
      minWidth: 40,
    },
  ];
  return !projectId || !apiClient || state.isLoadingTasks ? (
    <Spinner size="large" />
  ) : (
    <SpaceBetween size="l">
      <Container>
        <Header>{`Project ${state.projectName}`}</Header>
      </Container>
      <Container>
        <Table
          columnDefinitions={columnDefinitions}
          items={state.tasks}
          footer={
            <div className="noPrint">
              <Box textAlign="center">
                <Button
                  variant="primary"
                  onClick={() =>
                    dispatch({ type: "addTask", payload: { projectId } })
                  }
                >
                  New Task
                </Button>
              </Box>
            </div>
          }
        ></Table>
      </Container>
      <Container>
        {state.data.length > 0 ? (
          <TextContent>
            <ul>
              <li>50 Percentile: {state.data[50].toFixed?.(1)}</li>
              <li>80 Percentile: {state.data[80].toFixed?.(1)}</li>
              <li>90 Percentile: {state.data[90].toFixed?.(1)}</li>
            </ul>
          </TextContent>
        ) : (
          ""
        )}
      </Container>
      <Container>
        <CumSumChart data={state.data || []} />
      </Container>
      <div className="noPrint">
        <Container>
          <ColumnLayout columns={2}>
            <DeleteProject
              projectId={projectId}
              projects={projects}
              setProjects={setProjects}
              apiClient={apiClient}
              projectName={state.projectName}
            />
            <Button
              href="#/"
              variant="primary"
              onClick={async () => {
                const data = await apiClient.exportProject({
                  projectId,
                });
                const blob = converBase64toBlob(
                  data,
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                );
                const dataUri = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.hidden = true;
                a.setAttribute("href", dataUri);
                a.setAttribute("download", "document.xlsx");
                a.click();
              }}
            >
              Export
            </Button>
          </ColumnLayout>
        </Container>
      </div>
    </SpaceBetween>
  );
};

export default ProjectView;

function converBase64toBlob(content: string, contentType: string): Blob {
  contentType = contentType || "";
  const sliceSize = 512;
  const byteCharacters = atob(content);
  const byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  });
  return blob;
}
