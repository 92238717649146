/* tslint:disable */
/* eslint-disable */
/**
 * The Project Estimation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponseContent,
  CreateProjectRequestContent,
  CreateTaskRequestContent,
  EstimateRequestContent,
  EstimateResponseContent,
  ListProjectsResponseContent,
  ListTasksResponseContent,
  UpdateTaskRequestContent,
} from '../models';
import {
    ApiErrorResponseContentFromJSON,
    ApiErrorResponseContentToJSON,
    CreateProjectRequestContentFromJSON,
    CreateProjectRequestContentToJSON,
    CreateTaskRequestContentFromJSON,
    CreateTaskRequestContentToJSON,
    EstimateRequestContentFromJSON,
    EstimateRequestContentToJSON,
    EstimateResponseContentFromJSON,
    EstimateResponseContentToJSON,
    ListProjectsResponseContentFromJSON,
    ListProjectsResponseContentToJSON,
    ListTasksResponseContentFromJSON,
    ListTasksResponseContentToJSON,
    UpdateTaskRequestContentFromJSON,
    UpdateTaskRequestContentToJSON,
} from '../models';

export interface CreateProjectRequest {
    createProjectRequestContent: CreateProjectRequestContent;
}

export interface CreateTaskRequest {
    projectId: string;
    createTaskRequestContent: CreateTaskRequestContent;
}

export interface DeleteProjectRequest {
    projectId: string;
}

export interface DeleteTaskRequest {
    taskId: string;
    projectId: string;
}

export interface EstimateRequest {
    estimateRequestContent: EstimateRequestContent;
}

export interface ExportProjectRequest {
    projectId: string;
}

export interface ListTasksRequest {
    projectId: string;
}

export interface UpdateTaskRequest {
    taskId: string;
    projectId: string;
    updateTaskRequestContent?: UpdateTaskRequestContent;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createProjectRequestContent === null || requestParameters.createProjectRequestContent === undefined) {
            throw new runtime.RequiredError('createProjectRequestContent','Required parameter requestParameters.createProjectRequestContent was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectRequestContentToJSON(requestParameters.createProjectRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createTaskRaw(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createTask.');
        }

        if (requestParameters.createTaskRequestContent === null || requestParameters.createTaskRequestContent === undefined) {
            throw new runtime.RequiredError('createTaskRequestContent','Required parameter requestParameters.createTaskRequestContent was null or undefined when calling createTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/task`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaskRequestContentToJSON(requestParameters.createTaskRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createTask(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProject(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTaskRaw(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling deleteTask.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/task/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTask(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async estimateRaw(requestParameters: EstimateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EstimateResponseContent>> {
        if (requestParameters.estimateRequestContent === null || requestParameters.estimateRequestContent === undefined) {
            throw new runtime.RequiredError('estimateRequestContent','Required parameter requestParameters.estimateRequestContent was null or undefined when calling estimate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/estimate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EstimateRequestContentToJSON(requestParameters.estimateRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EstimateResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async estimate(requestParameters: EstimateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EstimateResponseContent> {
        const response = await this.estimateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportProjectRaw(requestParameters: ExportProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/export`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async exportProject(requestParameters: ExportProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProjectsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProjectsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProjectsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listProjects(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProjectsResponseContent> {
        const response = await this.listProjectsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listTasksRaw(requestParameters: ListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTasksResponseContent>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/task`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTasksResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listTasks(requestParameters: ListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTasksResponseContent> {
        const response = await this.listTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTaskRaw(requestParameters: UpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling updateTask.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/task/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTaskRequestContentToJSON(requestParameters.updateTaskRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateTask(requestParameters: UpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTaskRaw(requestParameters, initOverrides);
    }

}
