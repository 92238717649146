import { DefaultApi } from "@estimatron/backend-typescript-react-query-hooks";
import { State } from "../projectReducer";

export async function syncTasks(
  taskUpdates: State["taskUpdates"],
  apiClient: DefaultApi,
): Promise<Array<{ taskId: string; projectId: string }>> {
  const myUpdates = [...taskUpdates];
  const promises: Promise<{ taskId: string; projectId: string }>[] = [];
  for (const updateToConsider of myUpdates) {
    if (updateToConsider.action === "add") {
      promises.push(
        apiClient
          .createTask({
            projectId: updateToConsider.projectId,
            createTaskRequestContent: updateToConsider.payload,
          })
          .then(() => ({
            taskId: updateToConsider.taskId,
            projectId: updateToConsider.projectId,
          })),
      );
    } else if (updateToConsider.action === "remove") {
      promises.push(
        apiClient
          .deleteTask({
            projectId: updateToConsider.projectId,
            taskId: updateToConsider.taskId,
          })
          .then(() => ({
            taskId: updateToConsider.taskId,
            projectId: updateToConsider.projectId,
          })),
      );
    } else if (updateToConsider.action === "update") {
      promises.push(
        apiClient
          .updateTask({
            projectId: updateToConsider.projectId,
            taskId: updateToConsider.task.taskId,
            updateTaskRequestContent: updateToConsider.task,
          })
          .then(() => ({
            taskId: updateToConsider.taskId,
            projectId: updateToConsider.projectId,
          })),
      );
    } else {
      console.error("Action not valid", { updateToConsider });
    }
  }
  return Promise.all(promises);
}
