/* tslint:disable */
/* eslint-disable */
/**
 * The Project Estimation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskEstimate } from './TaskEstimate';
import {
    TaskEstimateFromJSON,
    TaskEstimateFromJSONTyped,
    TaskEstimateToJSON,
} from './TaskEstimate';

/**
 * 
 * @export
 * @interface EstimateRequestContent
 */
export interface EstimateRequestContent {
    /**
     * 
     * @type {Array<TaskEstimate>}
     * @memberof EstimateRequestContent
     */
    tasks: Array<TaskEstimate>;
}


/**
 * Check if a given object implements the EstimateRequestContent interface.
 */
export function instanceOfEstimateRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tasks" in value;

    return isInstance;
}

export function EstimateRequestContentFromJSON(json: any): EstimateRequestContent {
    return EstimateRequestContentFromJSONTyped(json, false);
}

export function EstimateRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstimateRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tasks': ((json['tasks'] as Array<any>).map(TaskEstimateFromJSON)),
    };
}

export function EstimateRequestContentToJSON(value?: EstimateRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tasks': ((value.tasks as Array<any>).map(TaskEstimateToJSON)),
    };
}

