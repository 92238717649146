import {
  AppLayout,
  BreadcrumbGroupProps,
  Spinner,
} from "@cloudscape-design/components";
import { ProjectItem } from "@estimatron/backend-typescript-react-query-hooks";
import { useEffect, useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { OnNavigate } from "./App";
import { Home } from "./Home";
import { useApi } from "./hooks/useApi";
import NavHeader from "./NavHeader";
import { NewProjectForm } from "./NewProjectForm";
import { ProjectList } from "./ProjectList";
import ProjectView from "./ProjectView";

export type SetProjects = React.Dispatch<React.SetStateAction<ProjectItem[]>>;
export type Projects = ProjectItem[];
export type SetActiveHref = React.Dispatch<React.SetStateAction<string>>;

export const AuthedView: React.FC = () => {
  const [activeHref, setActiveHref] = useState("/");
  const [, setActiveBreadcrumbs] = useState<BreadcrumbGroupProps.Item[]>([
    { text: "/", href: "/" },
  ]);

  const navigate = useNavigate();

  const onNavigate = useMemo(
    (): OnNavigate => (e) => {
      e.preventDefault();
      setActiveHref(e.detail.href);

      const segments = [
        "/",
        ...e.detail.href.split("/").filter((segment) => segment !== ""),
      ];
      setActiveBreadcrumbs(
        segments.map((segment, i) => {
          const href: string = segments
            .slice(0, i + 1)
            .join("/")
            .replace("//", "/");
          return {
            href,
            text: segment,
          };
        }),
      );
      navigate(e.detail.href);
    },
    [navigate, setActiveBreadcrumbs],
  );

  const api = useApi();
  const [projects, setProjects] = useState(null as ProjectItem[] | null);

  useEffect(() => {
    let active = true;
    void (async () => {
      if (api) {
        const currentProjects = await api?.listProjects();
        if (active) {
          setProjects(currentProjects?.projects || []);
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [api]);

  return (
    <>
      <NavHeader />
      {projects == null ? (
        <Spinner size="large" />
      ) : (
        <AppLayout
          toolsHide
          navigation={
            <ProjectList
              activeHref={activeHref}
              onNavigate={onNavigate}
              projects={projects}
            />
          }
          content={
            <Routes>
              <Route path="/" element={<Home projects={projects} />} />
              <Route
                path="project/new"
                element={
                  <NewProjectForm
                    projects={projects}
                    setProjects={setProjects as SetProjects}
                  />
                }
              />
              <Route
                path="project/:projectId"
                element={
                  <ProjectView
                    projects={projects}
                    setProjects={setProjects as SetProjects}
                    setActiveHref={setActiveHref}
                  />
                }
              />
            </Routes>
          }
        />
      )}
    </>
  );
};
