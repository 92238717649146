import { Authenticator } from "@aws-amplify/ui-react";
import {
  BreadcrumbGroupProps,
  SideNavigationProps,
} from "@cloudscape-design/components";
import { CancelableEventHandler } from "@cloudscape-design/components/internal/events";
import { Amplify } from "aws-amplify";
import { AuthUser, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useContext, useEffect, useState } from "react";
import { AuthedView } from "./AuthedView";
import { RuntimeConfigContext } from "./RuntimeConfigContext";
export type OnNavigate = CancelableEventHandler<
  BreadcrumbGroupProps.ClickDetail | SideNavigationProps.FollowDetail
>;

const App: React.FC = () => {
  const runtimeContext = useContext(RuntimeConfigContext);

  useEffect(() => {
    if (
      runtimeContext?.userPoolId &&
      runtimeContext?.userPoolWebClientId &&
      runtimeContext?.region &&
      runtimeContext?.identityPoolId
    ) {
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId: runtimeContext.userPoolId,
            userPoolClientId: runtimeContext.userPoolWebClientId,
            identityPoolId: runtimeContext.identityPoolId,
            loginWith: {
              oauth: {
                providers: ["Google"],
                redirectSignIn: isLocalHost()
                  ? ["http://localhost:3000"]
                  : ["https://getestimate.link"],
                redirectSignOut: isLocalHost()
                  ? ["http://localhost:3000"]
                  : ["https://getestimate.link"],
                responseType: "code",
                domain: runtimeContext.oauthDomain,
                scopes: [],
              },
            },
          },
        },
      });
    }
  }, [runtimeContext]);
  let [user, setUser] = useState<AuthUser | null>(null);
  useEffect(() => {
    let updateUser = async () => {
      try {
        let u = await getCurrentUser();
        setUser(u);
      } catch {
        setUser(null);
      }
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    void updateUser(); // check manually the first time because we won't get a Hub event
  }, []);

  return user ? (
    <AuthedView />
  ) : (
    <Authenticator variation="modal" socialProviders={["google"]}>
      <AuthedView />
    </Authenticator>
  );
};

function isLocalHost(): boolean {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );
}

export default App;
