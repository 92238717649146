import {
  Configuration,
  DefaultApi,
} from "@estimatron/backend-typescript-react-query-hooks";
import { useContext, useEffect, useState } from "react";
import * as middleware from "../api-client-middleware/sigv4-middleware";
import { RuntimeConfigContext } from "../RuntimeConfigContext";

export const useApi = () => {
  const runtimeContext = useContext(RuntimeConfigContext);
  const [state, setState] = useState({} as State);
  useEffect(() => {
    void (async () => {
      if (runtimeContext) {
        const apiUrl = runtimeContext.apiUrl;
        const config = new Configuration({
          fetchApi: window.fetch.bind(window),
          basePath: apiUrl.endsWith("/") ? apiUrl.slice(0, -1) : apiUrl,
          middleware: [
            middleware.addJsonContentTypeHeaderMiddleware,
            middleware.sigv4SignMiddleware(runtimeContext.region),
          ],
        });
        const apiClient = new DefaultApi(config);
        setState({ apiClient });
      }
    })();
  }, [runtimeContext]);
  return state?.apiClient;
};

interface State {
  apiClient?: DefaultApi;
}
