import { TaskItem } from "@estimatron/backend-typescript-react-query-hooks";
import { Reducer } from "react";
import { monotonicFactory } from "ulidx";
import { replaceMatching } from "./utils/array";

const ulid = monotonicFactory();

type TaskUpdate =
  | { action: "add"; taskId: string; payload: TaskItem; projectId: string }
  | { action: "remove"; taskId: string; projectId: string }
  | { action: "update"; taskId: string; task: TaskItem; projectId: string };

export interface State {
  tasks: TaskItem[];
  data: number[];
  taskUpdates: TaskUpdate[];
  projectName: string;
  isLoadingTasks: boolean;
  areBoundsValid: { [taskId: string]: boolean };
  isConfidenceValid: { [taskId: string]: boolean };
}

export type Actions =
  | {
      type: "setTaskName";
      payload: { taskId: string; newTaskName: string; projectId: string };
    }
  | {
      type: "setLowerBound";
      payload: { taskId: string; value: number; projectId: string };
    }
  | {
      type: "setUpperBound";
      payload: { taskId: string; value: number; projectId: string };
    }
  | {
      type: "setConfidence";
      payload: { taskId: string; value: number; projectId: string };
    }
  | {
      type: "setSamples";
      payload: number[] | undefined;
    }
  | { type: "removeTask"; payload: { taskId: string; projectId: string } }
  | {
      type: "addTask";
      payload: { projectId: string };
    }
  | {
      type: "tasksLoaded";
      payload: Array<TaskItem>;
    }
  | {
      type: "loadProject";
      payload: { projectName: string };
    }
  | {
      type: "updatesApplied";
      payload: Array<{ taskId: string }>;
    };

export function checkBounds(
  lower: number | undefined,
  upper?: number | undefined,
): boolean {
  return lower !== undefined && upper !== undefined && lower <= upper;
}

export function checkConfidence(c: number | undefined) {
  return c !== undefined && c >= 0 && c <= 100;
}

export const reducer: Reducer<State, Actions> = (state, action) => {
  let updatedTasks;
  switch (action.type) {
    case "loadProject":
      return {
        ...state,
        tasks: [],
        data: [],
        projectName: action.payload.projectName,
        isUpperMoreThanLower: {},
        isLowerLessThanUpper: {},
        isConfidenceValid: {},
      };
    case "setTaskName":
      updatedTasks = replaceMatching(
        state.tasks,
        (t) => t.taskId === action.payload.taskId,
        (t) => ({ ...t, taskName: action.payload.newTaskName }),
      );
      return {
        ...state,
        tasks: updatedTasks,
        taskUpdates: [
          ...state.taskUpdates.filter(
            (t) => t.taskId !== action.payload.taskId,
          ),
          {
            action: "update",
            taskId: action.payload.taskId,
            projectId: action.payload.projectId,
            task: updatedTasks.find(
              (t) => t.taskId === action.payload.taskId,
            ) as TaskItem,
          },
        ],
      };
    case "setLowerBound":
      const upperBound = state.tasks.find(
        (t) => t.taskId === action.payload.taskId,
      )?.upperBound;
      updatedTasks = replaceMatching(
        state.tasks,
        (t) => t.taskId === action.payload.taskId,
        (t) => ({ ...t, lowerBound: action.payload.value || 0 }),
      );
      return {
        ...state,
        areBoundsValid: {
          ...state.areBoundsValid,
          [action.payload.taskId]: checkBounds(
            action.payload.value,
            upperBound,
          ),
        },
        tasks: updatedTasks,
        taskUpdates: [
          ...state.taskUpdates.filter(
            (t) => t.taskId !== action.payload.taskId,
          ),
          {
            action: "update",
            projectId: action.payload.projectId,
            taskId: action.payload.taskId,
            task: updatedTasks.find(
              (t) => t.taskId === action.payload.taskId,
            ) as TaskItem,
          },
        ],
      };
    case "setConfidence":
      updatedTasks = replaceMatching(
        state.tasks,
        (t) => t.taskId === action.payload.taskId,
        (t) => ({ ...t, confidence: action.payload.value }),
      );
      return {
        ...state,
        isConfidenceValid: {
          ...state.isConfidenceValid,
          [action.payload.taskId]: checkConfidence(action.payload.value),
        },
        tasks: updatedTasks,
        taskUpdates: [
          ...state.taskUpdates.filter(
            (t) => t.taskId !== action.payload.taskId,
          ),
          {
            action: "update",
            projectId: action.payload.projectId,
            taskId: action.payload.taskId,
            task: updatedTasks.find(
              (t) => t.taskId === action.payload.taskId,
            ) as TaskItem,
          },
        ],
      };
    case "setUpperBound":
      const lowerBound = state.tasks.find(
        (t) => t.taskId === action.payload.taskId,
      )?.lowerBound;
      updatedTasks = replaceMatching(
        state.tasks,
        (t) => t.taskId === action.payload.taskId,
        (t) => ({ ...t, upperBound: action.payload.value || 0 }),
      );
      return {
        ...state,
        areBoundsValid: {
          ...state.areBoundsValid,
          [action.payload.taskId]: checkBounds(
            lowerBound,
            action.payload.value,
          ),
        },
        tasks: updatedTasks,
        taskUpdates: [
          ...state.taskUpdates.filter(
            (t) => t.taskId !== action.payload.taskId,
          ),
          {
            action: "update",
            projectId: action.payload.projectId,
            taskId: action.payload.taskId,
            task: updatedTasks.find(
              (t) => t.taskId === action.payload.taskId,
            ) as TaskItem,
          },
        ],
      };
    case "setSamples":
      return action.payload
        ? {
            ...state,
            data: action.payload,
          }
        : state;
    case "addTask":
      const addedTaskUuid = ulid();
      const taskAdded = {
        taskId: addedTaskUuid,
        lowerBound: 0,
        upperBound: 0,
        taskName: "",
        confidence: 90,
      };
      return {
        ...state,
        areBoundsValid: {
          ...state.areBoundsValid,
          [addedTaskUuid]: false,
        },
        isConfidenceValid: {
          ...state.isConfidenceValid,
          [addedTaskUuid]: true,
        },
        taskUpdates: [
          ...state.taskUpdates.filter((t) => t.taskId !== taskAdded.taskId),
          {
            action: "add",
            payload: taskAdded,
            taskId: taskAdded.taskId,
            projectId: action.payload.projectId,
          },
        ],
        tasks: [...state.tasks, taskAdded],
      };
    case "removeTask":
      let taskUpdates: TaskUpdate[] = [];
      if (
        state.taskUpdates.find(
          (t) => t.taskId === action.payload.taskId && t.action === "add",
        )
      ) {
        taskUpdates = state.taskUpdates.filter(
          (t) => t.taskId !== action.payload.taskId,
        );
      } else {
        taskUpdates = [
          ...state.taskUpdates.filter(
            (t) => t.taskId !== action.payload.taskId,
          ),
          {
            action: "remove",
            taskId: action.payload.taskId,
            projectId: action.payload.projectId,
          },
        ];
      }
      return {
        ...state,
        tasks: state.tasks.filter((t) => t.taskId !== action.payload.taskId),
        taskUpdates,
        isUpdatingTasks: true,
      };
    case "tasksLoaded":
      return {
        ...state,
        areBoundsValid: Object.fromEntries(
          action.payload.map((t) => [
            t.taskId,
            checkBounds(t.lowerBound, t.upperBound),
          ]),
        ),
        isConfidenceValid: Object.fromEntries(
          action.payload.map((t) => [t.taskId, checkConfidence(t.confidence)]),
        ),
        tasks: [...action.payload],
        isLoadingTasks: false,
      };
    case "updatesApplied":
      return {
        ...state,
        taskUpdates: [
          ...state.taskUpdates.filter(
            (t) => action.payload.findIndex((u) => t.taskId === u.taskId) < 0,
          ),
        ],
      };
    default:
      return state;
  }
};
