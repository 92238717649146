export function replaceMatching<T>(
  arr: T[],
  predicate: (e: T) => boolean,
  mutation: (e: T) => T,
): T[] {
  const indexToReplace = arr.findIndex(predicate);
  if (indexToReplace >= 0) {
    return [
      ...arr.slice(0, indexToReplace),
      mutation(arr[indexToReplace]),
      ...arr.slice(indexToReplace + 1),
    ];
  }
  return arr;
}
