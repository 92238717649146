import {
  Box,
  Button,
  ButtonDropdown,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { DefaultApi } from "@estimatron/backend-typescript-react-query-hooks";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Projects, SetProjects } from "./AuthedView";

async function deleteProject(
  apiClient: DefaultApi,
  projectId: string,
  setProjects: SetProjects,
  projects: Projects,
  navigate: NavigateFunction,
): Promise<void> {
  return apiClient
    .deleteProject({ projectId })
    .then(() => setProjects(projects.filter((p) => p.projectId !== projectId)))
    .then(() => navigate("/"));
}

export const DeleteProject: React.FC<{
  projectId: string;
  setProjects: SetProjects;
  projects: Projects;
  apiClient: DefaultApi;
  projectName: string;
}> = ({ projectId, projects, setProjects, apiClient, projectName }) => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <ButtonDropdown
        onItemClick={async (item) => {
          if (item.detail.id === "rm") {
            setModalVisible(true);
          }
        }}
        items={[
          {
            text: "Delete",
            id: "rm",
          },
        ]}
      >
        Settings
      </ButtonDropdown>
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">No</Button>
              <Button
                variant="primary"
                onClick={() =>
                  deleteProject(
                    apiClient,
                    projectId,
                    setProjects,
                    projects,
                    navigate,
                  )
                }
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Modal title"
      >
        Are you sure you want to remove the project {`${projectName}`} ?
      </Modal>
    </>
  );
};
