/* tslint:disable */
/* eslint-disable */
/**
 * The Project Estimation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstimateResponseContent
 */
export interface EstimateResponseContent {
    /**
     * 
     * @type {number}
     * @memberof EstimateResponseContent
     */
    mu: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateResponseContent
     */
    sigma: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EstimateResponseContent
     */
    samples?: Array<number>;
}


/**
 * Check if a given object implements the EstimateResponseContent interface.
 */
export function instanceOfEstimateResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mu" in value;
    isInstance = isInstance && "sigma" in value;

    return isInstance;
}

export function EstimateResponseContentFromJSON(json: any): EstimateResponseContent {
    return EstimateResponseContentFromJSONTyped(json, false);
}

export function EstimateResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstimateResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mu': json['mu'],
        'sigma': json['sigma'],
        'samples': !exists(json, 'samples') ? undefined : json['samples'],
    };
}

export function EstimateResponseContentToJSON(value?: EstimateResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mu': value.mu,
        'sigma': value.sigma,
        'samples': value.samples,
    };
}

