/* tslint:disable */
/* eslint-disable */
/**
 * The Project Estimation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectItem } from './ProjectItem';
import {
    ProjectItemFromJSON,
    ProjectItemFromJSONTyped,
    ProjectItemToJSON,
} from './ProjectItem';

/**
 * 
 * @export
 * @interface ListProjectsResponseContent
 */
export interface ListProjectsResponseContent {
    /**
     * 
     * @type {Array<ProjectItem>}
     * @memberof ListProjectsResponseContent
     */
    projects?: Array<ProjectItem>;
}


/**
 * Check if a given object implements the ListProjectsResponseContent interface.
 */
export function instanceOfListProjectsResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListProjectsResponseContentFromJSON(json: any): ListProjectsResponseContent {
    return ListProjectsResponseContentFromJSONTyped(json, false);
}

export function ListProjectsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProjectsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ProjectItemFromJSON)),
    };
}

export function ListProjectsResponseContentToJSON(value?: ListProjectsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ProjectItemToJSON)),
    };
}

