import {
  SpaceBetween,
  FormField,
  Input,
  Button,
  Container,
} from "@cloudscape-design/components";
import Form from "@cloudscape-design/components/form";
import React from "react";
import { useNavigate } from "react-router-dom";
import { monotonicFactory } from "ulidx";
import { Projects, SetProjects } from "./AuthedView";
import { useApi } from "./hooks/useApi";

const ulid = monotonicFactory();

export const NewProjectForm: React.FC<{
  projects: Projects;
  setProjects: SetProjects;
}> = ({ projects, setProjects }) => {
  const [state, setState] = React.useState({ projectName: "" });
  const api = useApi();
  const navigate = useNavigate();
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" formAction="none">
              Cancel
            </Button>
            <Button
              formAction="submit"
              variant="primary"
              onClick={() => {
                const projectId = ulid();
                api
                  ?.createProject({
                    createProjectRequestContent: {
                      projectId,
                      projectName: state.projectName,
                    },
                  })
                  .then(() => {
                    setProjects([
                      {
                        projectId,
                        projectName: state.projectName,
                      },
                      ...projects,
                    ]);
                  })
                  .then(() => {
                    navigate(`/project/${projectId}`);
                  })
                  .catch((e) => {
                    console.error(e);
                  });
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Project Name">
              <Input
                onChange={({ detail }) =>
                  setState({ ...state, projectName: detail.value })
                }
                value={state.projectName}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
    </form>
  );
};
