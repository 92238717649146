// Import models
import type {
  ApiErrorResponseContent,
  CreateProjectRequestContent,
  CreateTaskRequestContent,
  EstimateRequestContent,
  EstimateResponseContent,
  ListProjectsResponseContent,
  ListTasksResponseContent,
  UpdateTaskRequestContent,
  
} from '../models';

// Import request parameter interfaces
import {
    CreateProjectRequest,
    CreateTaskRequest,
    DeleteProjectRequest,
    DeleteTaskRequest,
    EstimateRequest,
    ExportProjectRequest,
    
    ListTasksRequest,
    UpdateTaskRequest,
    
    
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);


/**
 * useMutation hook for the CreateProject operation
 */
export const useCreateProject = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, CreateProjectRequest>, 'mutationFn'>
): UseMutationResult<void, TError, CreateProjectRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: CreateProjectRequest) => api.createProject(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the CreateTask operation
 */
export const useCreateTask = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, CreateTaskRequest>, 'mutationFn'>
): UseMutationResult<void, TError, CreateTaskRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: CreateTaskRequest) => api.createTask(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteProject operation
 */
export const useDeleteProject = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteProjectRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteProjectRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteProjectRequest) => api.deleteProject(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteTask operation
 */
export const useDeleteTask = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteTaskRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteTaskRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteTaskRequest) => api.deleteTask(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the Estimate operation
 */
export const useEstimate = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<EstimateResponseContent, TError, EstimateRequest>, 'mutationFn'>
): UseMutationResult<EstimateResponseContent, TError, EstimateRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: EstimateRequest) => api.estimate(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the ExportProject operation
 */
export const useExportProject = <TError = ResponseError>(
    params: ExportProjectRequest,
    options?: Omit<UseQueryOptions<string, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<string, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["exportProject", params], () => api.exportProject(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the ListProjects operation
 */
export const useListProjects = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<ListProjectsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListProjectsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listProjects"], () => api.listProjects(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the ListTasks operation
 */
export const useListTasks = <TError = ResponseError>(
    params: ListTasksRequest,
    options?: Omit<UseQueryOptions<ListTasksResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListTasksResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listTasks", params], () => api.listTasks(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the UpdateTask operation
 */
export const useUpdateTask = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, UpdateTaskRequest>, 'mutationFn'>
): UseMutationResult<void, TError, UpdateTaskRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdateTaskRequest) => api.updateTask(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

