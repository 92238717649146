import AreaChart from "@cloudscape-design/components/area-chart";
interface Props {
  data: number[];
}

export const CumSumChart: React.FC<Props> = (props) => {
  const data = apiDataToGraphData(props.data);
  return (
    <AreaChart
      hideFilter
      hideLegend
      height={250}
      series={[
        {
          title: "Time to Complete",
          type: "area",
          data,
          valueFormatter: (e) => e.toFixed(2),
        },
        {
          title: "Median",
          type: "threshold",
          y: 50,
          color: "green",
        },
        {
          title: "80 Percentile",
          type: "threshold",
          y: 80,
          color: "orange",
        },
        {
          title: "90 Percentile",
          type: "threshold",
          y: 90,
          color: "red",
        },
      ]}
      i18nStrings={{
        filterLabel: "Filter displayed data",
        filterPlaceholder: "Filter data",
        filterSelectedAriaLabel: "selected",
        detailPopoverDismissAriaLabel: "Dismiss",
        legendAriaLabel: "Legend",
        chartAriaRoleDescription: "line chart",
        detailTotalLabel: "Total",
        yTickFormatter: function o(e) {
          return e.toFixed(0) + "%";
        },
      }}
    />
  );
};

function apiDataToGraphData(data: number[]): Array<{ x: number; y: number }> {
  return data
    .slice(5, 96)
    .map((p, i) => ({
      x: p,
      y: i + 5,
    }))
    .filter(({ y }) => y % 5 === 0);
}
